import cx from 'classnames';
import {
  axios,
  EmailIcon,
  Maybe,
  MessageProgressComplete,
  MessageProgressFailed,
  MessageStatus,
  MessagingAPIForBrowser,
} from 'lunr-core/browser';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import {
  ProcessContactUsFormInput,
  ProcessContactUsFormResponse,
} from '../../../pages/api/process-contact-us-form';
import Anchor from '../../components/anchor';
import BackgroundStyleComponent, {
  BackgroundStyle,
} from '../../components/background-style';
import ViewportObserverOneTime from '../../components/viewport-observer-onetime';
import { Image } from '../../content-provider';
import { TargetOptions } from '../../content-provider/Link';
import { getMessagingAPIForBrowser } from '../../getMessagingAPIForBrowser';
import ContactUsForm from './ContactUsForm';

interface Props {
  title: string;
  subtitle: string;
  backgroundImage: Image;
  backgroundStyle: BackgroundStyle;
  applyNowLink: {
    text: string;
    target: string;
    externalUrl: string;
  };
}

interface State {
  submitted: boolean;
}

export default class ContactUsFormBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  render = () => {
    const titleHTML: string = this.props.title
      .replace('{', '<span class="ContactUsFormBlock--titleAlt">')
      .replace('}', '</span>');

    return (
      <div className={cx('ContactUsFormBlock')}>
        <BackgroundStyleComponent
          className="ContactUsFormBlock--inner"
          background={this.props.backgroundStyle}
        >
          <ViewportObserverOneTime>
            <div className="Container">
              <div className="g hand-one-whole desk-three-quarters">
                <AnimateHeight height={this.state.submitted ? 0 : 'auto'}>
                  <React.Fragment>
                    <div className="ContactUsContainer">
                      <div>
                        <div className="ContactUsFormBlock--header">
                          {this.props.title && (
                            <h2
                              className="ContactUsFormBlock--title"
                              dangerouslySetInnerHTML={{ __html: titleHTML }}
                            />
                          )}
                          {this.props.subtitle && (
                            <h6
                              className="ContactUsFormBlock--subtitle"
                              dangerouslySetInnerHTML={{
                                __html: this.props.subtitle,
                              }}
                            />
                          )}
                        </div>
                        <div className="ContactUsEmailBox">
                          <div>
                            <EmailIcon width={40} color="#6200ec" />
                          </div>
                          <div>
                            <span className="ReachOut">
                              Or you can also reach us directly at{' '}
                            </span>
                            <a
                              href="mailto:hello@lunrcapital.com"
                              className="Email"
                            >
                              hello@lunrcapital.com
                            </a>
                          </div>
                        </div>
                        <div className="ApplyNowContainer">
                          <Anchor
                            link={{
                              url: this.props.applyNowLink.externalUrl,
                              target: this.props.applyNowLink
                                .target as TargetOptions,
                              text: this.props.applyNowLink.text,
                            }}
                            className="ApplyNow text--uppercase"
                            forceArrow={true}
                          />
                        </div>
                      </div>

                      <ContactUsForm onSubmit={this.onSubmit} />
                    </div>
                  </React.Fragment>
                </AnimateHeight>

                <AnimateHeight height={this.state.submitted ? 'auto' : 0}>
                  <div className="ContactUsFormBlock--header">
                    <h2 className="ContactUsFormBlock--title">
                      Thanks for your submission!
                    </h2>
                    <h6 className="ContactUsFormBlock--subtitle">
                      We&apos;ll contact you soon.
                    </h6>
                  </div>
                  <div>
                    <button
                      onClick={() => this.setState({ submitted: false })}
                      className="btn btn--white"
                    >
                      send another one
                    </button>
                  </div>
                </AnimateHeight>
              </div>
            </div>
          </ViewportObserverOneTime>
        </BackgroundStyleComponent>
      </div>
    );
  };

  onSubmit = async (values: ProcessContactUsFormInput): Promise<boolean> => {
    let response = null;
    try {
      response = await axios.post<ProcessContactUsFormResponse>(
        '/api/process-contact-us-form',
        values,
        {
          headers: {
            'api-key': process.env.NEXT_PUBLIC_API_KEY!,
          },
        }
      );
    } catch (err) {
      console.warn('Error submitting form data!', err);
    }

    if (!response || response.status !== 200) {
      return false;
    }

    if (!response || response.status !== 200 || !response.data.messageID) {
      return false;
    }

    const messagingAPI: MessagingAPIForBrowser = getMessagingAPIForBrowser();
    const progress: Maybe<MessageProgressComplete | MessageProgressFailed> =
      await messagingAPI.waitForProgress(response.data.messageID);
    if (!progress || progress.status !== MessageStatus.COMPLETED) {
      return false;
    }

    this.setState({ submitted: true }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    return true;
  };
}
