import cx from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { Link, NavigationLink } from '../../content-provider';
import Anchor from '../anchor';
import Logo from '../logo';
import { NewsletterSubscription } from '../newsletter-subscription/NewsletterSubscription';
import WYSIWYG from '../wysiwyg';

interface Props {
  copyright: string | null;
  address: string | null;
  emailAddress: string | null;
  footerHeading: string | null;
  footerCTA: NavigationLink | null;
  footerLeftColumnLinks: Array<NavigationLink>;
  footerMiddleColumnLinks: Array<NavigationLink>;
  footerRightColumnLinks: Array<NavigationLink>;
  socialMediaLinks: Array<NavigationLink>;
}

const Footer: FC<Props> = (props: Props) => {
  const { query } = useRouter();
  let pageSlug = null;
  if (query.slug) {
    pageSlug = (query.slug as string[])[0];
  }
  return (
    <div className={cx('Footer')}>
      <div className="Footer--inner">
        <div className="container">
          <div className="gw">
            <div className="g">
              {!(pageSlug === 'contact-us') ? (
                <div className="Footer--getStarted text--center">
                  <h2 className="Footer--title h1">{props.footerHeading}</h2>
                  {props.footerCTA && (
                    <Anchor
                      className={cx('btn', 'btn--white')}
                      link={props.footerCTA}
                      forceArrow={false}
                    />
                  )}
                </div>
              ) : (
                <div className="EmptyContactUsFooter"></div>
              )}
            </div>

            <div className="g hidden-below-desk">
              <div className="Footer--divider" />
            </div>

            <div className="g hidden-below-desk desk-one-sixth">
              <Logo className="Footer--logo" />
            </div>

            <div className="g push--one-quarter one-quarter push--desk-zero">
              {renderInfo(props)}
            </div>

            {props.footerLeftColumnLinks &&
              props.footerLeftColumnLinks.length > 0 && (
                <div className="g push--one-quarter one-half push--desk-zero desk-one-sixth">
                  {renderLinks(props.footerLeftColumnLinks)}
                </div>
              )}

            {props.footerRightColumnLinks &&
              props.footerRightColumnLinks.length > 0 && (
                <div className="g push--one-quarter one-half push--desk-zero desk-one-sixth">
                  {renderLinks(props.footerRightColumnLinks)}
                </div>
              )}

            {props.socialMediaLinks && props.socialMediaLinks.length > 0 && (
              <div className="g push--one-quarter one-half push--desk-zero desk-one-sixth">
                {renderLinks(props.socialMediaLinks)}
              </div>
            )}

            <div className="g push--one-quarter one-half push--desk-zero desk-one-sixth">
              <NewsletterSubscription
                isLoading={false}
                setIsLoading={(isLoading) => console.log(isLoading)}
                onSuccessMessage={(message) => console.log(message)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderInfo = (props: Props) => {
  return (
    <div className="Footer--info">
      {props.copyright && (
        <WYSIWYG
          html={props.copyright}
          className="Footer--copyright text--uppercase"
        />
      )}

      {props.address && (
        <WYSIWYG
          html={props.address}
          className="Footer--address text--uppercase Footer--copyrightLast"
        />
      )}

      {props.emailAddress && (
        <a
          className="Footer--email a--white text--uppercase"
          href={`mailto:${props.emailAddress}`}
        >
          {props.emailAddress}
        </a>
      )}
    </div>
  );
};

const renderLinks = (links: Array<NavigationLink>) => {
  if (!links) {
    return null;
  }
  return (
    <ul className={cx('Footer--linkList v-list')}>
      {links.map((item: Link) => {
        return (
          <li className={cx('Footer--linkListItem')} key={item.url}>
            <Anchor
              link={item}
              className="Footer--link text--uppercase a--white"
              forceArrow={true}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default Footer;
