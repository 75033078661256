import cx from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  axios,
  createRecaptchaToken,
  LoadingSpinner,
  LoadingSpinnerSize,
} from 'lunr-core/browser';
import { FC, useState } from 'react';
import {
  NewsletterSubscriptionErrorResponse,
  NewsletterSubscriptionSuccessResponse,
} from '../../../pages/api/newsletter-subscription';
import {
  NewsletterSubscriptionValues,
  validateNewsletterSubscriptionValues,
} from '../../validations/newsletterSubscription';
import styles from './NewsletterSubscription.module.scss';

const formInitialValues: NewsletterSubscriptionValues = {
  email: '',
};

const sendFormRequest = async (values: NewsletterSubscriptionValues) => {
  try {
    if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
      throw new Error('Recaptcha not configured.');
    }

    const recaptcha: string = await createRecaptchaToken(
      process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      'newsletter_subscription_form'
    );

    const { data } = await axios.post<NewsletterSubscriptionSuccessResponse>(
      '/api/newsletter-subscription',
      {
        ...values,
        recaptcha,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.NEXT_PUBLIC_API_KEY!,
        },
      }
    );

    return data.message;
  } catch (err) {
    const defaultErrMessage =
      'Failed to subscribe email. Please try again later.';
    if (axios.isAxiosError(err)) {
      const errMessage =
        (err.response?.data as NewsletterSubscriptionErrorResponse).error ||
        defaultErrMessage;
      throw new Error(errMessage);
    }

    throw new Error(defaultErrMessage);
  }
};

type NewsletterSubscriptionFormProps = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onSuccessMessage: (message: string) => void;
};

export const NewsletterSubscription: FC<
  NewsletterSubscriptionFormProps
> = () => {
  const [error, setError] = useState<string | null>(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  return (
    <div className={styles.NewsletterSubscriptionBox}>
      <div className={styles.TitleContainer}>
        <span className={styles.Title}>
          Get the <span className={styles.Emphasis}>latest insights!</span>
        </span>
        <span className={styles.SubTitle}>Join Our Mailing List!</span>
      </div>
      {successMessage === null ? (
        <Formik
          initialValues={formInitialValues}
          onSubmit={async (values: NewsletterSubscriptionValues) => {
            setIsFormLoading(true);

            try {
              const message = await sendFormRequest(values);
              setSuccessMessage(message);
            } catch (err) {
              if (err instanceof Error) {
                setError(err.message);
              } else {
                setError('Unexpected error, please try again later.');
              }
            }

            setIsFormLoading(false);
          }}
          validate={validateNewsletterSubscriptionValues}
        >
          <Form>
            <div className={styles.FormFields}>
              <div>
                <Field
                  id="email"
                  className={cx('form-control', styles.EmailField)}
                  type="email"
                  name="email"
                  placeholder="Email Address"
                />
                <span className={styles.FieldError}>
                  <ErrorMessage name="email" />
                </span>
              </div>
              {error !== null ? (
                <div className={styles.FormError}>{error}</div>
              ) : null}
              <div className={styles.FormFooter}>
                <div className={styles.SubmitButtonContainer}>
                  <button
                    className="btn btn--white"
                    type="submit"
                    disabled={isFormLoading}
                  >
                    {isFormLoading ? (
                      <LoadingSpinner size={LoadingSpinnerSize.Tiny} />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      ) : (
        <span className={styles.SubTitle}>{successMessage}</span>
      )}
    </div>
  );
};

export default NewsletterSubscription;
