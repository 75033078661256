import { z } from 'zod';

export const NewsletterSubscriptionSchema = z.object({
  email: z
    .string({
      required_error: 'Email is required.',
    })
    .email('Email is invalid.'),
});

export const NewsletterSubscriptionRequestSchema =
  NewsletterSubscriptionSchema.extend({
    recaptcha: z
      .string({
        required_error: 'Recaptcha token is required.',
      })
      .trim()
      .min(1, 'Recaptcha token is required.'),
  });

export type NewsletterSubscriptionValues = z.infer<
  typeof NewsletterSubscriptionSchema
>;
export type NewsletterSubscriptionRequestValues = z.infer<
  typeof NewsletterSubscriptionRequestSchema
>;

export const validateNewsletterSubscriptionValues = (
  value: NewsletterSubscriptionValues
):
  | z.typeToFlattenedError<NewsletterSubscriptionValues>['fieldErrors']
  | undefined => {
  const { success, error } = NewsletterSubscriptionSchema.safeParse(value);

  if (success) {
    return undefined;
  }

  return error?.flatten().fieldErrors;
};

export const validateNewsletterSubscriptionRequestValues = (
  value: unknown
):
  | z.typeToFlattenedError<NewsletterSubscriptionRequestValues>['fieldErrors']
  | undefined => {
  const { success, error } =
    NewsletterSubscriptionRequestSchema.safeParse(value);

  if (success) {
    return undefined;
  }

  return error?.flatten().fieldErrors;
};
